/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'check-circle-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M16 8A8 8 0 110 8a8 8 0 0116 0m-3.97-3.03a.75.75 0 00-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 00-1.06 1.06L6.97 11.03a.75.75 0 001.079-.02l3.992-4.99a.75.75 0 00-.01-1.05z"/>',
    },
});
